/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-unstable-nested-components */
import { yupResolver } from '@hookform/resolvers/yup';
import { mdiAlertOutline } from '@mdi/js';
import Icon from '@mdi/react';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import CreditCardInput from 'react-credit-card-input';
import { Controller, useForm } from 'react-hook-form';
import { Toaster, toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { useCheckOutMutation, usePricingMutation } from '../../features/pricing/pricingApi';
import { changePlan, setCouponCode, setPricingDetails } from '../../features/pricing/pricingSlice';
import SuccessModal from '../Modal/SuccessModal';

import Alert from '../Alert/Alert';
import './checkoutForm.scss';

const CheckoutForm = () => {
  // const [isMonthly, setIsMonthly] = useState(true);
  // const [isPremiumPlan, setIPremiumPlan] = useState(false);
  const [cardNo, setCardNo] = useState('');
  const [couponCodeText, setCouponCodeText] = useState('');
  const [cardDate, setCardDate] = useState('');
  const [cvc, setCvc] = useState('');
  const [successModalShow, seSuccessModalShow] = useState(false);
  const { isMonthly, couponCode, pricingDetails } = useSelector((state) => state.pricing);
  const dispatch = useDispatch();

  const [
    pricing,
    { data: pricingData, isLoading: priceLoading, error: priceError, isError: isPriceError },
  ] = usePricingMutation();

  const [
    checkOut,
    {
      data: checkOutData,
      isLoading: checkOutLoading,
      error: checkOutError,
      isError: ischeckOutError,
    },
  ] = useCheckOutMutation();
  // console.log(checkOutError);
  useEffect(() => {
    pricingData?.totalPrice !== undefined &&
      dispatch(
        setPricingDetails({
          totalPrice: pricingData?.totalPrice,
          firstMonth: isMonthly ? pricingData?.firstMonthPrice : false,
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pricingData?.firstMonthPrice, pricingData?.totalPrice]);

  const validationSchema = yup.object({
    planSelect: yup.string().trim().required('Plan select is required field'),
    fName: yup.string().trim().required('First name is required field'),
    lName: yup.string().trim().required('Last name is required field'),
    email: yup.string().email().trim().required('Email name is required field'),
    eComAddOn: yup.string().trim(),
    coupon: yup.string().trim(),
    // eComAddOn: yup.string().trim().required('Plan select is required field'),
  });
  // console.log(pricingData);
  // couponCod handler with debounce
  // let timeOut;
  // const handelCouponCode = (e) => {
  //   const text = e.target.value;
  //   clearTimeout(timeOut);
  //   timeOut = setTimeout(() => {
  //     dispatch(setCouponCode(text));
  //   }, 1000);
  // };
  const handelCouponCode = (e) => {
    const text = e.target.value;
    setCouponCodeText(text);
    couponCodeText !== '' && dispatch(setCouponCode('none'));
  };
  const handelCouponSubmit = () => {
    couponCodeText !== '' && dispatch(setCouponCode(couponCodeText));
  };

  const switchHandel = (checked) => {
    dispatch(changePlan(checked));
  };

  const {
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    // defaultValues: {
    //   eComAddOn:
    //     watch('planSelect') === 'Basic' || watch('planSelect') === 'Standard'
    //       ? 'Select Ecommerce Add-on'
    //       : watch('eComAddOn'),
    // },
  });

  const selectPlanField = watch('planSelect') || '';
  const ecomAddOnField = watch('eComAddOn') || 'None';
  // const couponCode = watch('coupon');
  // console.log(selectPlanField);

  const handelSubmit = (data) => {
    if (cardNo !== '' && cardDate !== '' && cvc !== '') {
      const finalData = {
        ...data,
        isMonthly,
        eComAddOn: data?.eComAddOn || 'None',
        couponCode: couponCode === '' ? 'none' : couponCode,
        cardInfo: {
          cardNo: cardNo.split(' ').join(''),
          cardDate: cardDate.split(' / ').join(''),
          cvc,
        },
      };
      // console.log('data', finalData);
      checkOut(finalData);
    }
  };

  // useEffect(() => {
  //   setIPremiumPlan(selectPlanField === 'Premium');
  // }, [selectPlanField]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    selectPlanField !== '' &&
      pricing({
        isMonthly,
        planName: selectPlanField === 'Select Plan' ? 'none' : selectPlanField,
        eComAddOn:
          // eslint-disable-next-line no-nested-ternary
          ecomAddOnField === 'Select Ecommerce Add-on' ? 'None' : ecomAddOnField,
        coupon: couponCode === '' ? 'none' : couponCode,
      });
  }, [couponCode, ecomAddOnField, isMonthly, pricing, selectPlanField]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    isPriceError && toast.error(priceError?.data?.error?.message || 'Somethings went wrong!');
  }, [isPriceError, priceError?.data?.error?.message]);

  // final checkout data
  useEffect(() => {
    // console.log('checkOutError::', checkOutError);
    // console.log('ischeckOutError::', ischeckOutError);
    // console.log('checkOutData::', checkOutData);

    if (checkOutData?.status === 'success') {
      // console.log(checkOutData);
      // console.log(checkOutData?.plan?.passwordSetUrl);
      seSuccessModalShow(true);
      if (window && window.parent) {
        window.parent.postMessage({ url: checkOutData?.plan?.passwordSetUrl, redirect: true }, '*');
      }
    }
  }, [checkOutData, checkOutError, ischeckOutError]);

  return (
    <div className="mt-5">
      <SuccessModal show={successModalShow} />
      <Toaster />
      <Container>
        <form className="checkoutForm" onSubmit={handleSubmit(handelSubmit)}>
          <Row>
            <Col lg={6}>
              <div className="planSelectDetails">
                <div className="plan-header d-flex align-items-center justify-content-between mb-3">
                  <h3 className="text-white m-0">Select Plan</h3>
                  <div className="swithBtn">
                    <BootstrapSwitchButton
                      width={130}
                      height={35}
                      checked={isMonthly}
                      onlabel="MONTHLY"
                      offlabel="YEARLY"
                      onstyle="warning"
                      offstyle="warning"
                      onChange={switchHandel}
                    />
                  </div>
                </div>

                <div className="formFiledBorder">
                  <Controller
                    name="planSelect"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <div className="mb-4 input-group">
                        <Form.Select
                          className={!!errors.planSelect && 'is-invalid'}
                          value={value}
                          onChange={onChange}
                          id="planSelect"
                        >
                          <option>Select Plan</option>
                          <option value="Basic">Basic</option>
                          <option value="Standard">Standard</option>
                          <option value="Premium">Premium</option>
                        </Form.Select>

                        <div className="packagePrice">
                          <p className="m-0">${pricingData?.planPrice || 0}</p>
                        </div>
                        {errors.planSelect ? (
                          <p className="text-danger">{errors.planSelect.message}</p>
                        ) : null}
                      </div>
                    )}
                  />
                  {/* {isPremiumPlan && (
                    <Controller
                      name="eComAddOn"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value } }) => (
                        <div className="mb-4 input-group">
                          <Form.Select
                            className={!!errors.eComAddOn && 'is-invalid'}
                            value={value}
                            onChange={onChange}
                            id="eComAddOn"
                          >
                            <option>Select Ecommerce Add-on</option>
                            <option value="None">None</option>
                            <option value="Standard">Standard</option>
                            <option value="Advanced">Advanced</option>
                            <option value="Unlimited">Unlimited</option>
                          </Form.Select>

                          <div className="packagePrice">
                            <p className="m-0">${pricingData?.eComAddOnPrice || 0}</p>
                          </div>
                          {errors.eComAddOn ? (
                            <p className="text-danger">{errors.eComAddOn.message}</p>
                          ) : null}
                        </div>
                      )}
                    />
                  )} */}

                  <Controller
                    name="eComAddOn"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <div className="mb-4 input-group">
                        <Form.Select
                          disabled={selectPlanField === 'Select Plan' || selectPlanField === ''}
                          className={!!errors.eComAddOn && 'is-invalid'}
                          value={value}
                          onChange={onChange}
                          id="eComAddOn"
                        >
                          <option>Select Ecommerce Add-on</option>
                          <option value="None">None</option>
                          <option value="Standard">Standard</option>
                          <option value="Advanced">Advanced</option>
                          <option value="Unlimited">Unlimited</option>
                        </Form.Select>

                        <div className="packagePrice">
                          <p className="m-0">${pricingData?.eComAddOnPrice || 0}</p>
                        </div>
                        {errors.eComAddOn ? (
                          <p className="text-danger">{errors.eComAddOn.message}</p>
                        ) : null}
                      </div>
                    )}
                  />
                  <div className="input-group mb-3">
                    <Form.Control
                      className="disableInput"
                      // value={couponCode}
                      onChange={handelCouponCode}
                      id="coupon"
                      placeholder="Coupon code here"
                    />
                    <button
                      disabled={priceLoading}
                      onClick={handelCouponSubmit}
                      className="btn apply-coupon-btn"
                      type="button"
                    >
                      Apply Coupon
                    </button>
                  </div>
                </div>
              </div>

              {/* <div className="totalAmount mt-3">
                <div className="text-end">
                  {priceLoading ? (
                    <span className="spinner-border text-secondary text-end" role="status" />
                  ) : (
                    <h4 className="text-white text-end">
                      Total: <span className="fw-bold ">${pricingDetails.totalPrice}</span>
                    </h4>
                  )}
                </div>
              </div> */}

              <div className="priceDetails my-3 text-white fa-weight">
                <div className="d-flex justify-content-between align-items-center">
                  <p>
                    Selected Plan (
                    {(selectPlanField !== 'Select Plan' && selectPlanField) || 'None'})
                  </p>
                  <p>
                    $
                    {isMonthly
                      ? pricingData?.planPrice || 0
                      : (pricingData?.planPrice && pricingData.planPrice * 12) || 0}
                  </p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p>(+) E-commerce Add-on {ecomAddOnField && `(${ecomAddOnField})`}</p>
                  <p>
                    $
                    {isMonthly
                      ? pricingData?.eComAddOnPrice || 0
                      : (pricingData?.eComAddOnPrice && pricingData.eComAddOnPrice * 12) || 0}
                  </p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p>(-) First Month Discount</p>
                  <p>
                    $
                    {isMonthly
                      ? (pricingData?.planPrice &&
                          (
                            pricingData.planPrice +
                            pricingData.eComAddOnPrice -
                            pricingData.firstMonthPrice
                          ).toFixed(2)) ||
                        0
                      : (pricingData?.planPrice &&
                          (pricingData.planPrice - pricingData.firstMonthPrice).toFixed(2)) ||
                        0}
                  </p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="m-0">(-) Coupon Discount</p>
                  <p>${pricingData?.discountAmount || 0}</p>
                </div>
                <hr />

                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="text-white text-start mb-0">Total</h5>
                  <h5
                    style={{ opacity: priceLoading ? '0.5' : '1' }}
                    className="text-white text-end mb-0"
                  >
                    <span className="fw-bold">
                      $
                      {isMonthly
                        ? pricingDetails?.firstMonth || 0
                        : parseFloat(pricingDetails.totalPrice).toFixed(2) || 0}
                    </span>
                  </h5>
                </div>

                {isMonthly && (
                  <>
                    <hr />

                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="text-white text-start mb-0 fs-6">
                        Renewal Subscription Amount :
                      </h5>
                      <h5
                        style={{ opacity: priceLoading ? '0.5' : '1' }}
                        className="text-white text-end mb-0"
                      >
                        <span className="fw-bold">${pricingDetails.totalPrice}</span>
                      </h5>
                    </div>
                  </>
                )}
              </div>
            </Col>
            <Col lg={6}>
              <div className="accountInfoField">
                <h3 className="text-white text-center mb-lg-4">Account Information</h3>
                <div className="formFiledBorder">
                  <Controller
                    name="fName"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <div className="mb-4">
                        <Form.Control
                          className={!!errors.fName && 'is-invalid'}
                          value={value}
                          onChange={onChange}
                          id="fName"
                          placeholder="First Name"
                        />
                        {errors.fName ? (
                          <p className="text-danger">{errors.fName.message}</p>
                        ) : null}
                      </div>
                    )}
                  />
                  <Controller
                    name="lName"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <div className="mb-4">
                        <Form.Control
                          className={!!errors.lName && 'is-invalid'}
                          value={value}
                          onChange={onChange}
                          id="lName"
                          placeholder="Last Name"
                        />
                        {errors.lName ? (
                          <p className="text-danger">{errors.lName.message}</p>
                        ) : null}
                      </div>
                    )}
                  />
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <div className="mb-4">
                        <Form.Control
                          className={!!errors.email && 'is-invalid'}
                          value={value}
                          onChange={onChange}
                          id="email"
                          placeholder="E-Mail"
                        />
                        {errors.email ? (
                          <p className="text-danger">{errors.email.message}</p>
                        ) : null}
                      </div>
                    )}
                  />
                  <div className="cardInput">
                    <CreditCardInput
                      // eslint-disable-next-line react/no-unstable-nested-components
                      cardCVCInputRenderer={({ handleCardCVCChange, props }) => (
                        <input
                          className="card-input-field"
                          {...props}
                          onChange={handleCardCVCChange((e) => setCvc(e.target.value))}
                        />
                      )}
                      cardExpiryInputRenderer={({ handleCardExpiryChange, props }) => (
                        <input
                          className="card-input-field"
                          {...props}
                          onChange={handleCardExpiryChange((e) => setCardDate(e.target.value))}
                        />
                      )}
                      cardNumberInputRenderer={({ handleCardNumberChange, props }) => (
                        <input
                          className="card-input-field"
                          {...props}
                          onChange={handleCardNumberChange((e) => setCardNo(e.target.value))}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="priceDetails my-3 text-white fa-weight">
                <div className="d-flex justify-content-center align-content-center">
                  <Icon color="#fd8205" className="icon" path={mdiAlertOutline} size={2} />
                  <p
                    style={{ fontSize: '12px', textAlign: 'justify' }}
                    className="mb-0 ms-3 fs-10 "
                  >
                    <b>Note:</b> By submitting your payment, you understand that your credit card
                    will be charged the amount in the “Total” box. You also understand that your
                    credit card will be automatically charged on the anniversary date of your
                    subscription.
                  </p>
                </div>
              </div>

              <div className="d-flex justify-content-end align-items-center mt-3">
                <button disabled={checkOutLoading} className="btn submitBtn" type="submit">
                  Submit{' '}
                  {checkOutLoading && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </button>
              </div>
            </Col>
          </Row>
        </form>
        <Alert
          message={checkOutError?.data?.message || checkOutError?.data?.payment?.message}
          showAlert={ischeckOutError}
        />
      </Container>
    </div>
  );
};

export default CheckoutForm;
