/* eslint-disable no-return-assign */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-unstable-nested-components */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { Toaster, toast } from 'react-hot-toast';
import * as yup from 'yup';

import moment from 'moment/moment';
import { useAddCouponMutation } from '../../features/coupon/couponApi';
import './addCoupon.scss';

const AddCoupon = () => {
  const [addCoupon, { data, isLoading, error, isError, status }] = useAddCouponMutation();

  const validationSchema = yup.object({
    code: yup.string().trim().required('Coupon code is required field'),
    type: yup.string().trim().required('Coupon Type is required field'),
    value: yup
      .number()
      .typeError('Discount amount must be a number')
      .required('Discount amount is required field'),
    isMonthly: yup.string().trim().required('Coupon monthly or yearly is required field'),
    // useLimit: yup.number().typeError('Coupon limit amount must be a number'),
    expDate: yup
      .date('Coupon expiration date must be a date type')
      .typeError('Coupon expiration date must be a date type')
      .required('Coupon monthly or yearly is required field'),
  });

  const {
    watch,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const selectPlanField = watch('type');
  // console.log(selectPlanField);
  const handelSubmit = (couponData) => {
    const modifyCouponData = {
      ...couponData,
      isMonthly: couponData.isMonthly === 'true',
      useLimit: couponData.useLimit !== '' ? couponData.useLimit : 999999999999999,
      expDate: moment(couponData.expDate).format('YYYY-MM-DD h:mm:ss'),
    };
    // console.log(modifyCouponData);
    addCoupon(modifyCouponData);
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    isError && toast.error(error?.data?.error?.message || 'Somethings went wrong!');
  }, [error?.data?.error?.message, isError]);

  useEffect(() => {
    if (status === 'fulfilled') {
      toast.success(data?.message);
      reset();
    }
  }, [data, reset, status]);

  return (
    <div className="mt-5">
      <Toaster />
      <Container>
        <form className="addCouponForm" onSubmit={handleSubmit(handelSubmit)}>
          <Row>
            <Col lg={6} className="offset-lg-3">
              <div>
                <h3 className="text-white text-center mb-lg-4">Add Coupon</h3>
                <div className="formFiledBorder">
                  <Controller
                    name="code"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <div className="mb-4">
                        <Form.Control
                          className={!!errors.code && 'is-invalid'}
                          value={value}
                          onChange={onChange}
                          id="code"
                          placeholder="Coupon Code"
                        />
                        {errors.code ? <p className="text-danger">{errors.code.message}</p> : null}
                      </div>
                    )}
                  />

                  <Controller
                    name="type"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <div className="mb-4">
                        <Form.Select
                          className={!!errors.type && 'is-invalid'}
                          value={value}
                          onChange={onChange}
                          id="type"
                        >
                          <option>Coupon Type</option>
                          <option value="percent">Percent</option>
                          <option value="fixed">Fixed Amount</option>
                        </Form.Select>
                        {errors.type ? <p className="text-danger">{errors.type.message}</p> : null}
                      </div>
                    )}
                  />

                  <Controller
                    name="value"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <div className="mb-4">
                        <Form.Control
                          className={!!errors.value && 'is-invalid'}
                          value={value}
                          onChange={onChange}
                          id="value"
                          placeholder={`Set Discount ${
                            selectPlanField === 'fixed' ? 'Amount' : 'Percent'
                          }`}
                        />
                        {errors.value ? (
                          <p className="text-danger">{errors.value.message}</p>
                        ) : null}
                      </div>
                    )}
                  />

                  <Controller
                    name="isMonthly"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <div className="mb-4">
                        <Form.Select
                          className={!!errors.isMonthly && 'is-invalid'}
                          value={value}
                          onChange={onChange}
                          id="isMonthly"
                        >
                          <option>Select Coupon For Monthly or Yearly</option>
                          <option value="true">Monthly</option>
                          <option value="false">Yearly</option>
                        </Form.Select>
                        {errors.isMonthly ? (
                          <p className="text-danger">{errors.isMonthly.message}</p>
                        ) : null}
                      </div>
                    )}
                  />

                  <Controller
                    name="useLimit"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <div className="mb-4">
                        <Form.Control
                          className={!!errors.useLimit && 'is-invalid'}
                          value={value}
                          onChange={onChange}
                          id="useLimit"
                          placeholder="Coupon Limit"
                        />
                        {errors.value ? (
                          <p className="text-danger">{errors?.useLimit?.message}</p>
                        ) : null}
                      </div>
                    )}
                  />

                  <Controller
                    name="expDate"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <div className="mb-2">
                        <Form.Control
                          className={!!errors.expDate && 'is-invalid'}
                          onFocus={(e) => (e.target.type = 'date')}
                          onBlur={(e) => (e.target.type = 'text')}
                          value={value}
                          onChange={onChange}
                          id="expDate"
                          placeholder="Coupon Expiration Date"
                        />
                        {errors.value ? (
                          <p className="text-danger">{errors?.expDate?.message}</p>
                        ) : null}
                      </div>
                    )}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-end align-items-center mt-3">
                <button disabled={isLoading} className="btn submitBtn" type="submit">
                  Add Coupon
                  {isLoading && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </button>
              </div>
            </Col>
          </Row>
        </form>
      </Container>
    </div>
  );
};

export default AddCoupon;
