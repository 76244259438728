import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isMonthly: false,
  couponCode: 'none',
  pricingDetails: {
    totalPrice: 0,
    firstMonth: 0,
  },
};

const pricingSlice = createSlice({
  name: 'pricing',
  initialState,
  reducers: {
    changePlan: (state, action) => {
      state.isMonthly = action.payload;
    },
    setCouponCode: (state, action) => {
      state.couponCode = action.payload;
    },
    // setTotalPrice: (state, action) => {
    //   state.totalPrice = action.payload;
    // },
    // setFirstMonth: (state, action) => {
    //   state.firstMonth = action.payload;
    // },
    setPricingDetails: (state, action) => {
      state.pricingDetails = action.payload;
    },
  },
});

export default pricingSlice;
export const { changePlan, setCouponCode, setPricingDetails } = pricingSlice.actions;
