/* eslint-disable consistent-return */
/* eslint-disable import/no-extraneous-dependencies */
// import 'flowbite';
import React, { useEffect, useState } from 'react';
import { InfinitySpin } from 'react-loader-spinner';
import { Route, Routes } from 'react-router-dom';
import './App.scss';
import AddCoupon from './Components/AddCoupon/AddCouponForm';
import CheckoutForm from './Components/Checkout/CheckoutForm';

function App() {
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    // const onPageLoad = () => {
    //   setPageLoading(false);
    // };

    // // Check if the page has already loaded
    // if (document.readyState === 'complete') {
    //   onPageLoad();
    // } else {
    //   window.addEventListener('load', onPageLoad);
    //   // Remove the event listener when component unmounts
    //   return () => window.removeEventListener('load', onPageLoad);
    // }

    setTimeout(() => {
      setPageLoading(false);
    }, 1500);
  }, []);

  return (
    <div className="App">
      {pageLoading && (
        <div className="preLoader">
          <InfinitySpin width="200" color="#fd8205" />
        </div>
      )}
      <Routes>
        <Route path="/" element={<CheckoutForm />} />
        <Route
          path="/aDdCOuPoN/a3/7nMQc0g7XiB5zbfVp8aSKdsM95p/add/Coupon/hVcl42V5VyEb"
          element={<AddCoupon />}
        />
      </Routes>
    </div>
  );
}

export default App;
