import { apiSlice } from '../api/apiSlice';

const couponApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addCoupon: builder.mutation({
      query: (data) => ({
        url: '/coupon',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useAddCouponMutation } = couponApi;
