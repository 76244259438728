/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import successGif from '../../images/successPayment.svg';
import './SuccessModal.scss';

const SuccessModal = (props) => {
  const [shoeLoader, setShoeLoader] = useState(true);
  const [redirectTime, setRedirectTime] = useState(15);

  useEffect(() => {
    if (props.show) {
      if (redirectTime === 10) {
        setShoeLoader(false);
      }
      if (redirectTime > 0) {
        setTimeout(() => {
          setRedirectTime(redirectTime - 1);
        }, 1000);
      }
    }
  }, [props.show, redirectTime]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="successModal"
      centered
    >
      <Modal.Body>
        <div className="successGif">
          <img src={successGif} alt="successGif" />
        </div>
        <h3 className="text-center fw-bold mb-3">
          Congratulations!! <br />
          Your payment was successfull
        </h3>
        <div>
          {shoeLoader && (
            <>
              <p className="text-center">Generating your site link please wait...</p>
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </>
          )}
        </div>
        {!shoeLoader && (
          <div>
            <div className="alert alert-light w-50 m-auto text-center redirect-alert" role="alert">
              <p className="m-0">
                Your site link has been generated. Will automatically redirect within{' '}
                <span className="fw-bold fs-4" style={{ color: '#ff7500' }}>
                  {redirectTime}
                </span>{' '}
                seconds.
              </p>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SuccessModal;
