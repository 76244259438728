import { mdiAlertOutline, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import './alert.scss';

const Alert = ({ message, showAlert }) => {
  const [hideAlert, sethideAlert] = useState(false);
  const closeAlert = () => {
    sethideAlert(false);
  };
  useEffect(() => {
    sethideAlert(showAlert);
  }, [showAlert]);

  return (
    <div className={`${hideAlert ? 'd-block' : 'd-none'}`}>
      <div className="alert-bg">
        <button type="button" className="close_btn" onClick={closeAlert}>
          <Icon className="icon" path={mdiClose} size={1} />
        </button>
        <Row className="d-flex justify-content-center align-items-center">
          <Col xs={2}>
            <div className="icon__wrapper">
              <Icon className="icon" path={mdiAlertOutline} size={1} />
            </div>
          </Col>
          <Col xs={10}>
            <p>{message || 'Something went wrong'}</p>
          </Col>
        </Row>
      </div>{' '}
    </div>
  );
};

export default Alert;
