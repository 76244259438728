import { apiSlice } from '../api/apiSlice';

const pricingAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    pricing: builder.mutation({
      query: (data) => ({
        url: '/price',
        method: 'POST',
        body: data,
      }),
    }),
    checkOut: builder.mutation({
      query: (data) => ({
        url: '/checkout',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { usePricingMutation, useCheckOutMutation } = pricingAPI;
